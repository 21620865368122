import http from '@/libs/axios';

let pro = process.env.VUE_APP_BASE_API


/**
 * 查询代理商列表
 * @param data
 * @returns {*}
 */
export function agentList(data){
  return http({
    url: `${pro}/v1/user/list`,
    method: 'post',
    requestBody: true,
    data
  })
}

/**
 * 删除代理商
 * @param id
 * @returns {*}
 */
export function delAgent(id){
  return http({
    url: `${pro}/v1/user/delAgent?id=${id}`,
    method: 'post'
  })
}

/**
 * 查询费率模板列表
 * @param query
 * @returns {*}
 */
export function rateTempList(query){
  return http({
    url: `${pro}/v1/temp/list`,
    method: 'get',
    params: query
  })
}

/**
 * 新增费率模板
 * @param data
 * @returns {*}
 */
export function addRateTemplate(data){
  return http({
    url: `${pro}/v1/temp/add`,
    method: 'post',
    requestBody: true,
    data
  })
}

/**
 * 查询代理商创建的渠道列表
 * @returns {*}
 */
export function getAgentTenantList(id){
  return http({
    url: `${pro}/v1/agentTenant/list/${id}`,
    method: 'get'
  })
}

/**
 * 批量手动进件
 * @param data
 * @returns {*}
 */
export function batchHfAddPiece(data){
  return http({
    url: `${pro}/v1/piece/batchAddHfMch`,
    method: 'post',
    requestBody: true,
    data
  })
}
/**
 * 代理商创建渠道
 * @param data
 * @returns {*}
 */
export function addAgentTenant(data){
  return http({
    url: `${pro}/v1/agentTenant/add`,
    method: 'POST',
    requestBody: true,
    data
  })
}



/**
 * 查询首页信息
 * @returns {*}
 */
export function getHomeInfo(data){
  return http({
    url: `${pro}/v1/home/homeInfo`,
    method: 'get',
    params: data
  })
}

/**
 * 新增代理商
 * @param data
 * @returns {*}
 */
export function addAgent(data){
  return http({
    url: `${pro}/v1/user/addAgent`,
    method: 'post',
    requestBody: true,
    data
  })
}

/**
 * 查询指定代理商
 * @returns {*}
 */
export function getAgent(id){
  return http({
    url: `${pro}/v1/user/getAgent/${id}`,
    method: 'get'
  })
}

/**
 * 修改md5密钥
 * @param data
 * @returns {*}
 */
export function updateKey(data){
  return http({
    url: `${pro}/v1/user/updateKey`,
    method: 'POST',
    requestBody: true,
    data
  })
}
