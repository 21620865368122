<template>
  <div style="background: #ffffff;border: 0px" :style="{width: !collapse ? '220px' : '76px'}">
    <div style="display:flex;align-items: center;padding-bottom: 40px;padding-top: 10px"
         :style="{marginLeft: collapse ? '15px' : '40px'}">
      <img src="../assets/img/hss2.png" style="width: 125px;height: 30px;margin-top: 10px">
<!--      <span v-if="!collapse" style="color: #2c3e5f;font-size: 20px;font-weight: 800;margin-left: 15px">汇扫扫</span>-->
    </div>
    <el-menu
      :style="{width: collapse ? '76px' : '220px'}"
      style="height: 1080px;border: 0px;"
      :router="true"
      @select="getActiveMenuItem"
      :default-active="getActiveMenuItem()"
      :collapse="collapse"
      class="el-menu-vertical-demo">
      <div v-for="(item,index) in routeList">
        <el-menu-item :index="item.path" v-if="!item.children && !item.meta.hidden">
          <div style="display:flex;align-items: center">
            <i :class="item.meta.icon" :style="{fontSize: collapse ? '26px' : '20px'}"></i>
            <span slot="title" style="margin-left: 20px;margin-top: -3px" v-if="!collapse">
                <el-badge v-if="item.meta.isNew" value="新"><span style="font-weight: bold">{{ item.meta.title }}</span></el-badge>
                <span v-else style="font-family: 'Hiragino Sans GB';font-weight: bold">{{
                    item.meta.title
                  }}</span>
              </span>
          </div>
        </el-menu-item>
        <el-submenu :index="item.path" v-else-if="item.children && item.path !== '/'" :class="collapse ? 'hidd':''">
          <template slot="title" style="display: flex;align-items: center">
            <div style="display:flex;align-items: center">
              <i :class="item.meta.icon" :style="{fontSize: collapse ? '26px' : '20px'}"></i>
              <span v-if="!collapse" slot="title"
                    style="margin-left: 20px;font-family: 'PingFang SC';font-weight: bold">{{
                  item.meta.title
                }}</span>
            </div>
          </template>
          <el-menu-item-group v-if="item.children">
            <el-menu-item :route="item.path + '/' + children.path"
                          v-for="(children,index) in item.children"
                          :index="item.path + '/' + children.path" :key="index" v-if="!children.meta.hidden">
              <el-badge v-if="children.meta.isNew" value="新"><span>{{ children.meta.title }}</span></el-badge>
              <span v-else style="font-family: 'PingFang SC';">{{ children.meta.title }}</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      collapse: false,
      defaultActive: '/',
      routeList: []
    }
  },
  watch: {
    $route(e) {
      this.defaultActive = e.fullPath
      this.getActiveMenuItem()
    },
  },
  mounted() {
    this.routeList = this.$router.options.routes
  },
  methods:{
    getActiveMenuItem() {
       return this.$route.fullPath;
    }
  }
}
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

/deep/ .el-badge__content {
  top: 23px !important;
  right: 0px !important;
}

/deep/ .el-submenu__title {
  display: flex;
}

/deep/ .el-menu-item {
  display: flex;
}

/deep/ .el-submenu__icon-arrow {
  color: #1A1C1F;
  font-weight: bold;
}

/deep/ .el-menu--collapse .el-sub-menu__title .el-sub-menu__icon-arrow {
  display: none;
}

/deep/ .hidd .el-submenu__icon-arrow {
  display: none !important;
}
/deep/.el-menu-item-group__title{
  padding: 0;
}
/deep/.el-menu-item{
  padding-top: 5px;
  padding-bottom: 5px;
}
/deep/.el-submenu{
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
