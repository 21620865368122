// import parseTime, formatTime and set to filter

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

export function dateFormat(date, fmt) {
    var date = new Date(date);
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    var o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    };
    for (var k in o) {
      if (new RegExp('('+k+')').test(fmt)) {
        var str = o[k] + '';
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
      }
    }
    return fmt;
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    {value: 1E18, symbol: 'E'},
    {value: 1E15, symbol: 'P'},
    {value: 1E12, symbol: 'T'},
    {value: 1E9, symbol: 'G'},
    {value: 1E6, symbol: 'M'},
    {value: 1E3, symbol: 'k'}
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function numberFormat(number, decimals) {
  /*
  * 参数说明：
  * number：要格式化的数字
  * decimals：保留几位小数
  * dec_point：小数点符号
  * thousands_sep：千分位符号
  * */
  let regPos = /^\d+(\.\d+)?$/; //非负浮点数
  let regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
  if (!regPos.test(number) && !regNeg.test(number)) {
    return number;
  }
  number = (number + '').replace(/[^0-9+-Ee.]/g, '');
  let n = !isFinite(+number) ? 0 : +number,

    prec = !isFinite(+2) ? 0 : Math.abs(2),
    sep = ',',
    dec = '.',
    s = '',
    type = (typeof decimals === 'undefined') ? '0' : decimals,
    toFixedFix = function (n, prec) {
      let k = Math.pow(10, prec);
      return '' + Math.floor(n * k) / k;
    };
  s = (prec ? toFixedFix(n, prec) : '' + Math.floor(n)).split('.');
  let re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, "$1" + sep + "$2");
  }
  if (type == 1) {
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
    }
  }
  return s.join(dec);
}


// 切割数
function slicefn (v) {
  v = "" + v;
  if (v.length >= 4) {
    let a = v.slice(-3);
    let b = v.slice(0, v.length - 3);
    return slicefn(b) + ',' + a
  } else {
    return v;
  }
}
export function newNumberFormat(v, decimals) {
  v = (v || 0).toString().replace(/[^\d-\.]/, '')
  let xsd = v.split(".");
  let negativeFlg = false
  if (xsd[0].match(/^-/)) {
    negativeFlg = true;
    xsd[0] = xsd[0].replace(/^-/, '')
  }

  if (xsd.length == 1) {
    xsd[0] = slicefn(xsd[0]);
    if (decimals) {
      return (negativeFlg ? '-' : '') + xsd[0] + '.' + '0'.padEnd(decimals, '0');
    } else {
      return (negativeFlg ? '-' : '') + xsd[0];
    }
  } else {
    if (decimals == 0) {
      return (negativeFlg ? '-' : '') + slicefn(xsd[0]);// 直接舍去小数
    }
    if (xsd[1].length > 2 && decimals == null) {
      decimals = 2
    }
    let addFlg = false;
    if (decimals) {
      xsd[1] = xsd[1].padEnd(decimals, '0')
      if (decimals >= xsd[1].length || !(xsd[1][decimals] > 4)) {
        xsd[1] = xsd[1].slice(0, decimals)
      } {
        let a = xsd[1].length;
        xsd[1] = addOnefn(xsd[1].slice(0, decimals))
        if (xsd[1].length > a) {
          xsd[1] = xsd[1].slice(-a);
          addFlg = true
        }
      }
    }
    if (addFlg) {
      xsd[0] = addOnefn(xsd[0])
    }
    return (negativeFlg ? '-' : '') + slicefn(xsd[0]) + '.' + xsd[1]
  }
}
function addOnefn (v) {
  v = (v || 0).toString()
  let a = v.slice(0, v.length - 1)
  let b = v.slice(-1)
  let c = Number(b) + 1
  if (c == 10) {
    return addOnefn(a) + '0'
  } else return '' + a + c
}


export function filterMethod (node, val) {
  if (!!~node.text.indexOf(val) || !!~node.text.toUpperCase().indexOf(val.toUpperCase())) {
    return true
  }
};
