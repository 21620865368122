<template>
  <div style="display: flex;overflow: hidden">
    <div>
      <div style="width: 1100px;height: 200px;display: flex;" class="leftBox">
        <div style="padding: 20px">
          <div style="width: 320px">
            <span style="background: #135AFA;width: 2px;border-radius: 4px;margin-right: 10px;">&nbsp;</span>
            <span style="font-weight: bold;font-size: 14px;">今日账户收益情况</span>
            <span
              style="font-size: 12px;color: #86909C;margin-left: 15px;">更新时间：{{
                new Date().getFullYear() + '年' + parseInt(new Date().getMonth() + 1) + '月' + new Date().getDate() + '日'
              }}</span>
            <i class="el-icon-refresh-right"
               style="font-size: 12px;color: #86909C;margin-left: 10px;cursor: pointer;"></i>
          </div>
          <div style="margin-left: 40px;margin-top: 30px;display: flex;flex-direction: column;">
            <span style="font-size: 14px;color: #50565D;font-weight: bold">今日收益</span>
            <span style="font-size: 28px;font-weight: bold;margin-top: 10px">0</span>
            <span
              style="font-size: 12px;color: #50565D;font-weight: bold;margin-top: 10px;">昨日收益：<span>0</span></span>
            <span style="font-size: 11px;margin-top: 10px;color: #86909C;">较昨日多出：<span
              style="color: red;">0</span></span>
          </div>
        </div>
        <div style="width: 160px;">
          <div style="margin-left: 40px;margin-top: 69px;display: flex;flex-direction: column;width: 150px;">
            <span style="font-size: 14px;color: #50565D;font-weight: bold">今日订单金额</span>
            <span style="font-size: 28px;font-weight: bold;margin-top: 10px">
              <CountTo :startVal="0" :endVal="info.money"/>
            </span>
            <span
              style="font-size: 12px;color: #50565D;font-weight: bold;margin-top: 10px;">昨日订单金额：<span>  <CountTo :startVal="0" :endVal="info.preMoney"/></span></span>
            <span style="font-size: 11px;margin-top: 10px;color: #86909C;">较昨日多出：<span
              style="color: red;"><CountTo :startVal="0" :endVal="info.money > info.preMoney ? info.money - info.preMoney : 0"/></span></span>
          </div>
        </div>
        <div
          style="display: flex;justify-content: space-between;width: 400px;background: linear-gradient(-135deg, rgba(19, 194, 176, 0.2), 24%, rgb(255, 255, 255), rgb(255, 255, 255));margin-left: 225px;">
          <div style="margin-left: 40px;margin-top: 70px;display: flex;flex-direction: column;">
            <span style="font-size: 14px;color: #50565D;font-weight: bold">今日订单数</span>
            <span style="font-size: 28px;font-weight: bold;margin-top: 10px"><CountTo :startVal="0" :endVal="info.orderNum"/></span>
            <span style="font-size: 12px;color: #50565D;font-weight: bold;margin-top: 10px;">昨日订单数：<span><CountTo :startVal="0" :endVal="info.preOrderNum"/></span></span>
            <span style="font-size: 11px;margin-top: 10px;color: #86909C;">较昨日多出：<span
              style="color: red;"><CountTo :startVal="0" :endVal="info.orderNum > info.preOrderNum ? info.orderNum - info.preOrderNum : 0"/></span></span>
          </div>
          <img src="../../assets/img/home/right_icon.png"
               style="width: 150px;height: 50px;margin-right: 20px;margin-top: 20px">
        </div>
      </div>
      <div style="width: 1100px;height: 150px" class="leftBox">
        <div style="padding: 20px">
          <div style="width: 320px">
            <span style="background: #135AFA;width: 2px;border-radius: 4px;margin-right: 10px;">&nbsp;</span>
            <span style="font-weight: bold;font-size: 14px;">常用功能</span>
          </div>
          <div style="display: flex;margin-top: 10px;">
            <el-row class="rowMargin">
              <el-col><i class="iconfont el-icon-kehuguanli" @click="$router.push('/mch/mchList')"></i></el-col>
              <el-col class="text">商户管理</el-col>
            </el-row>
            <el-row class="rowMargin">
              <el-col><i class="iconfont el-icon-zhigeiwodebeifen" @click="$router.push('/mch/inputPiece')"></i></el-col>
              <el-col class="text">进件管理</el-col>
            </el-row>
            <el-row class="rowMargin">
              <el-col><i class="iconfont el-icon-zhuanjieguanlibeifen" @click="$router.push('/mch/integral')"></i></el-col>
              <el-col class="text">轮询管理</el-col>
            </el-row>
            <el-row class="rowMargin">
              <el-col><i class="iconfont el-icon-quanbuyingyongbeifen" @click="$router.push('/order/orderList')"></i></el-col>
              <el-col class="text">订单管理</el-col>
            </el-row>
            <el-row class="rowMargin">
              <el-col><i class="iconfont el-icon-a-kejichanyefuwubeifen2"  @click="$router.push('/pieceManager')"></i></el-col>
              <el-col class="text">审核管理</el-col>
            </el-row>
            <el-row class="rowMargin">
              <el-col><i class="iconfont el-icon-zonghenenghaobeifen" @click="$router.push('/channelManager')"></i></el-col>
              <el-col class="text">通道配置</el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div style="width: 1100px;height: 350px" class="leftBox">
        <div style="padding: 20px;">
          <div style="width: 100%">
            <span style="background: #135AFA;width: 2px;border-radius: 4px;margin-right: 10px;">&nbsp;</span>
            <span style="font-weight: bold;font-size: 14px;">交易流水统计</span>
            <div style="float: right;display: flex">
              <i class="el-icon-arrow-left" style="cursor: pointer;"></i>
              <span
                style="margin-left: 10px;margin-right:10px;font-size: 12px;font-weight: bold;">{{ new Date().getFullYear() + '年' + new Date().getMonth() + '月' }}</span>
              <i class="el-icon-arrow-right"
                 style="color: #50565D;background: rgba(134,144,156,0.3);border-radius: 50%;cursor: pointer"></i>
            </div>
          </div>
        </div>
        <div id="jylstj" style="height: 300px;width: 1100px"></div>
      </div>
      <div style="width: 1100px;height: 330px" class="leftBox">
        <div style="padding: 20px;">
          <div style="width: 100%">
            <span style="background: #135AFA;width: 2px;border-radius: 4px;margin-right: 10px;">&nbsp;</span>
            <span style="font-weight: bold;font-size: 14px;">通道与进件状态分布</span>
            <div style="display: flex;justify-content: space-between;width: 90%;margin-left: 70px;">
              <div id="tenantId" style="width: 500px;height: 300px"></div>
              <div id="remarkFl" style="width: 500px;height: 300px;margin-left: 10px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%">
      <div class="rightBox" style="height: 200px">
        <img src="@/assets/img/home/homeright.png" style="width: 500px;height: 200px;margin-left: 10px;cursor: pointer;">
      </div>
      <div class="rightBox" style="height: 400px">
        <div style="padding: 20px;">
          <div style="width: 100%;display: flex;justify-content: space-between;align-content: center;">
            <div>
              <span style="background: #135AFA;width: 2px;border-radius: 4px;margin-right: 10px;">&nbsp;</span>
              <span style="font-weight: bold;font-size: 14px;">审核列表</span>
            </div>
            <span style="color: #86909C;font-size: 12px;cursor: pointer;" @click="$router.push('/pieceManager')">更多 <i class="el-icon-arrow-right"></i></span>
          </div>
          <div style="padding: 10px 20px 20px 20px;">
            <div style="display: flex;justify-content: space-between;margin-top: 20px;" v-for="(item,index) in info.peieceList" :key="index">
              <div style="display: flex;flex-direction: column;">
                <div style="display:flex;">
                  <el-badge is-dot style="margin-top: 5px;" type="danger"></el-badge>
                  <span style="font-size: 14px;font-weight: bold;margin-left: 15px;">{{item.mchFor}}</span>
                </div>
                <span style="font-size: 12px;color: #86909C;margin-top: 5px;margin-left: 25px;">
                  {{item.tenantId === 1 ? '银盛' : item.tenantId === 2 ? '汇付' : item.tenantId === 3 ? '易生' : item.tenantId === 4 ? '新生' : '汇聚'}} |
                  {{ item.incomType === 1 ? '小微' : item.aptitude === 0 ? '个体' : '企业' }}
                  <span style="margin-left: 15px;">{{item.createTime.split('T')[0].split('-')[0] + '年' + item.createTime.split('T')[0].split('-')[1] + '月' + item.createTime.split('T')[0].split('-')[2] + '日'}}</span>
                </span>
              </div>
              <el-button type="text" style="color: #135AFA;font-size: 12px;" @click="$router.push('/pieceManager')">去审核</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="rightBox" style="height: 400px">
        <div style="padding: 20px;">
          <div style="width: 100%;display: flex;justify-content: space-between;align-content: center;">
            <div>
              <span style="background: #135AFA;width: 2px;border-radius: 4px;margin-right: 10px;">&nbsp;</span>
              <span style="font-weight: bold;font-size: 14px;">交易排行榜</span>
            </div>
            <span style="color: #86909C;font-size: 12px;cursor: pointer;"  @click="$router.push('/order/orderList')">更多 <i class="el-icon-arrow-right"></i></span>
          </div>
          <div style="padding: 0 20px 20px 20px;">
            <div style="display: flex;justify-content: space-between;margin-top: 25px;margin-left: 10px;" v-for="(item,index) in info.topMoney" :key="index">
              <div style="display: flex;flex-direction: column;">
                <div style="display:flex;">
                  <span style="font-size: 14px;font-weight: bold;margin-left: 15px;">{{ item.mchFor }}</span>
                </div>
                <span style="font-size: 12px;color: #86909C;margin-top: 5px;margin-left: 15px;">商户号：{{ item.userCode }}</span>
              </div>
              <span style="font-size: 14px;font-weight: bold"><CountTo :startVal="0" :endVal="item.money"/>元</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import {getHomeInfo} from "@/api/agent/agent";
import {numberFormat} from "@/filters";
import CountTo from 'vue-count-to'
export default {
  name: "Home",
  components:{CountTo},
  data() {
    return {
      info: {}
    }
  },
  mounted() {
    this.getHomeInfo()
  },
  methods: {
    numberFormat,
    initJyls() {
      let _this = this;
      let init = echarts.init(document.getElementById('jylstj'));
      let option = {
        legend: {
          // top: '5%',
          // right: '5%',
          itemHeight: 3,
          itemWidth: 15,
          textStyle: {
            color: '#556677'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,0)',
              shadowColor: 'rgba(0,0,0,0)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: '#fff',
          textStyle: {
            color: '#5c6c7c'
          },
          padding: [10, 10],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)'
        },
        grid: {
          top: '15%'
        },
        xAxis: [{
          type: 'category',
          data: _this.info.orderMonthList ? _this.info.orderMonthList.map(x => x.timest.split("-")[2]) : [],
          axisLine: {
            lineStyle: {
              color: '#DCE2E8'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            show: true,
            textStyle: {
              color: '#556677'
            },
            // 默认x轴字体大小
            fontSize: 12,
            // margin:文字到x轴的距离
            margin: 15
          },
          splitLine: {
            show: false
          },
          axisPointer: {
            label: {
              // padding: [11, 5, 7],
              padding: [0, 0, 10, 0],
              margin: 15,
              // 移入时的字体大小
              fontSize: 12,
              backgroundColor: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: '#fff' // 0% 处的颜色
                },
                  {
                    offset: 0.86,
                    color: '#fff' // 0% 处的颜色
                  },
                  {
                    offset: 0.86,
                    color: '#33c0cd' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#33c0cd' // 100% 处的颜色
                  }],
                global: false // 缺省为 false
              }
            }
          },
          boundaryGap: false
        }],
        yAxis: [{
          type: 'value',
          name: '订单金额',
          nameTextStyle: {
            color: '#86909C'
          },
          show: true,
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            name: '123',
            lineStyle: {
              color: '#DCE2E8',
              opacity: 0 //将y轴刻度线的opacity属性设置为0，即为隐藏刻度线
            }
          },
          axisLabel: {
            textStyle: {
              color: '#556677'
            }
          },
          splitLine: {
            show: true
          }
        },
          {
            type: 'value',
            show: true,
            name: '订单数量',
            nameTextStyle: {
              color: '#86909C'
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#DCE2E8',
                opacity: 0 //将y轴刻度线的opacity属性设置为0，即为隐藏刻度线
              }
            },
            axisLabel: {
              textStyle: {
                color: '#556677'
              }
            },
            splitLine: {
              show: true
            }
          }],
        series: [{
          name: '订单金额',
          type: 'line',
          data: _this.info.orderMonthList ? _this.info.orderMonthList.map(x => x.money) : [],
          symbolSize: 1,
          symbol: 'circle',
          yAxisIndex: 0,
          showSymbol: false,
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(19,90,250,0.3)'
              },
                {
                  offset: 1,
                  color: 'rgba(19,90,250,0)'
                }
              ]),
              shadowColor: 'rgba(19,90,250,0.2)',
              shadowBlur: 20
            }
          },
          lineStyle: {
            width: 2,
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
              offset: 0,
              color: '#135AFA'
            },
              {
                offset: 1,
                color: '#135AFA'
              }
            ]),
            shadowColor: 'rgba(158,135,255, 0.3)',
            shadowBlur: 10,
            shadowOffsetY: 20
          }
        },
          {
            name: '订单数',
            type: 'line',
            data: this.info.orderMonthList ? this.info.orderMonthList.map(x => x.orderNum) : [],
            symbolSize: 1,
            yAxisIndex: 1,
            symbol: 'circle',
            showSymbol: false,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(147,89,247,0.3)'
                },
                  {
                    offset: 1,
                    color: 'rgba(147,89,247,0)'
                  }
                ],),
                shadowColor: 'rgba(147,89,247,0.2)',
                shadowBlur: 20
              }
            },
            lineStyle: {
              width: 2,
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                offset: 0,
                color: '#9359E3'
              },
                {
                  offset: 1,
                  color: '#9359E3'
                }
              ]),
              shadowColor: 'rgba(254,154,139, 0.3)',
              shadowBlur: 10,
              shadowOffsetY: 20
            }
          }]
      }
      option.yAxis[0].max = Math.ceil(Math.max.apply(null, option.series[0].data) / 5) * 5;
      option.yAxis[0].interval = Math.ceil(Math.max.apply(null, option.series[0].data) / 5);
      option.yAxis[1].max = Math.ceil(Math.max.apply(null, option.series[1].data) / 5) * 5;
      option.yAxis[1].interval = Math.ceil(Math.max.apply(null, option.series[1].data) / 5);
      option.yAxis[0].min = 0;
      option.yAxis[1].min = 0;
      init.setOption(option)

    },
    initTenantId() {
      let init = echarts.init(document.getElementById('tenantId'));
      init.setOption({
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '25%',
          right: '15%',
          itemHeight: 15,
          orient: 'vertical',
          icon: 'circle',
          itemGap: 20,
          itemWidth: 15,
          textStyle: {
            color: '#556677'
          }
        },
        series: [{
          width: 500,
          height: 300,
          left: '-87px',
          top: '-20px',
          type: 'pie',
          center: ['50%', '50%'],
          radius: ['24%', '45%'],
          clockwise: true,
          avoidLabelOverlap: true,
          hoverOffset: 15,
          label: {
            show: true,
            position: 'outside',
            formatter: '{b} {d}%',
            textStyle: {
              color: '#50565D'
            }
          },
          labelLine: {
            normal: {
              length: 20,
              length2: 30,
              lineStyle: {
                width: 1
              },
              show: false
            }
          },
          data: [{
            'name': '银盛',
            'value': this.info.tenant.filter(x => x.tenantId === 1).map(x => x.count)[0],
            'itemStyle': {
              normal: {
                color: new echarts.graphic.LinearGradient(0.2, 1, 0.3, 0, [
                  {
                    offset: 0,
                    color: 'rgba(19, 90, 250, 1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(19, 90, 250, 0.4)',
                  },
                ]),
              },
            }
          },
            {
              'name': '汇付',
              'value': this.info.tenant.filter(x => x.tenantId === 2).map(x => x.count)[0],
              'itemStyle': {
                normal: {
                  color: new echarts.graphic.LinearGradient(0.2, 1, 0.3, 0, [
                    {
                      offset: 0,
                      color: 'rgba(19, 194, 176, 1)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(19, 194, 176,0.4)',
                    },
                  ]),
                },
              }
            },
            {
              'name': '汇聚',
              'value': this.info.tenant.filter(x => x.tenantId === 5).map(x => x.count)[0],
              'itemStyle': {
                normal: {
                  color: new echarts.graphic.LinearGradient(0.2, 1, 0.3, 0, [
                    {
                      offset: 0,
                      color: 'rgba(247, 186, 30, 1)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(247, 186, 30, 0.4)',
                    },
                  ]),
                },
              }
            }],
        }]
      })
    },
    initRemarkFx() {
      let init = echarts.init(document.getElementById('remarkFl'));
      init.setOption({
        radar: {
          center: ['50%', '50%'],
          radius: '75%',
          shape: 'circle',
          nameGap: 10,
          indicator:  this.info.remark.map(x => {
            return {
              name: x.remark.substring(0,10),
              color: '#556677'
            }
          }),
          splitLine: {
            show: false

          },
          splitArea: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        series: [{
          // name: '家庭融合包',
          type: 'radar',
          data: [[582, 421.2, 622.1, 625.3, 265, 224]],
          label: {
            show: false,
            formatter: function (params) {
              return params.value;
            },
            textStyle: {
              color: '#50565D'
            },
            distance: 10,
          },
          symbolSize: [0, 0],
          lineStyle: { //边缘颜色
            width: 0
          },
          itemStyle: {
            borderWidth: 1,
            color: '#fff',
            borderColor: '#F2F063',
          },
          areaStyle: {
            color: '#82a5fc',
            opacity: 0.6
          }
        },
          {
            type: 'radar',
            data: [
              [1000, 1000, 1000, 1000, 1000, 1000],
            ],
            symbol: 'none',
            lineStyle: {
              width: 0
            },
            itemStyle: {
              color: '#e1eeff'
            },

            areaStyle: {
              color: '#4175F5',
              opacity: 0.06
            }
          },
          {
            type: 'radar',
            data: [
              [900, 900, 900, 900, 900, 900],
            ],
            symbol: 'none',
            lineStyle: {
              width: 0
            },
            itemStyle: {
              color: '#e1eeff'
            },

            areaStyle: {
              color: '#2C72C8',
              opacity: 0.12
            }
          },
          {
            type: 'radar',
            data: [
              [800, 800, 800, 800, 800, 800]
            ],

            symbol: 'none',
            lineStyle: {
              width: 0
            },
            itemStyle: {
              color: '#e1eeff'
            },

            areaStyle: {
              color: '#4175F5',
              opacity: 0.18
            }
          },
          {
            type: 'radar',
            data: [
              [700, 700, 700, 700, 700, 700]
            ],

            symbol: 'none',
            lineStyle: {
              width: 0
            },
            itemStyle: {
              color: '#e1eeff'
            },

            areaStyle: {
              color: '#4175F5',
              opacity: 0.19
            }
          },
          {
            type: 'radar',
            data: [
              [600, 600, 600, 600, 600, 600],
            ],

            symbol: 'none',
            lineStyle: {
              width: 0
            },
            itemStyle: {
              color: '#e1eeff'
            },

            areaStyle: {
              color: '#4175F5',
              opacity: 0.17
            }
          },
          {
            type: 'radar',
            data: [
              [500, 500, 500, 500, 500, 500],
            ],
            symbol: 'none',
            lineStyle: {
              width: 0
            },
            itemStyle: {
              color: '#e1eeff'
            },

            areaStyle: {
              color: '#4175F5',
              opacity: 0.16
            }
          },
          {
            type: 'radar',
            data: [
              [400, 400, 400, 400, 400, 400],
            ],

            symbol: 'none',
            lineStyle: {
              width: 0
            },
            itemStyle: {
              color: '#e1eeff'
            },

            areaStyle: {
              color: '#4175F5',
              opacity: 0.13
            }
          },
        ]
      })
    },
    getHomeInfo(){
      let param = {
        agentId: this.$store.getters.userInfo.userName === 'admin' ? null : this.$store.getters.userInfo.id
      }
      getHomeInfo(param).then(res =>{
        if (res.success){
          this.info = res.data
          this.initJyls()
          this.initTenantId()

        }
      })
    }
  }
}
</script>

<style scoped>
.leftBox {
  border: 0px;
  margin-top: 16px;
  background: #ffffff;
  border-radius: 4px;
}

.rightBox {
  border: 0px;
  margin-top: 16px;
  margin-left: 16px;
  background: #ffffff;
  border-radius: 4px;
}

.iconfont {
  font-size: 50px;
}

.rowMargin {
  margin-left: 65px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;

  .text {
    margin-top: 10px;
  }
}

</style>
